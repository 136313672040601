import { integrationsServiceRequest } from 'config';
import { API_URLS } from 'common/constants';
import { setAuthHeaders } from 'utils/auth';
import { AccessToken } from '../../types/Auth';

export const configurationChoicesService = {
  getChoises: (accessToken: AccessToken) =>
    integrationsServiceRequest.get(API_URLS.configurationChoicesService.configurationChoices, {
      headers: setAuthHeaders(accessToken),
    }),
};
