export enum FormDataTypes {
  STRING = 'String',
  CRON = 'Cron',
  INTEGER = 'Integer',
  BOOLEAN = 'Boolean',
  REGEX = 'RegExp',
  CHOICE = 'Choice',
}

export const defaultFilterOptions = {
  id: 0,
  value: '',
  label: 'All',
};

export enum EmptyData {
  UNKNOWN = 'Unknown',
  NONE = 'None',
}
