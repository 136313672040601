import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import { IntegrationSystem, ConfigurationChoice } from '../../types/Integrations';
import { AccessToken } from '../../types/Auth';

export enum ActionTypeKeys {
  SET_MAIN_NAV_COLLAPSED = 'SHARED/SET_MAIN_NAV_COLLAPSED',
  SET_SUB_NAV_COLLAPSED = 'SHARED/SET_SUB_NAV_COLLAPSED',

  GET_INTEGRATION_SYSTEMS = 'SHARED/GET_INTEGRATION_SYSTEMS',
  GET_INTEGRATION_SYSTEMS_SUCCESS = 'SHARED/GET_INTEGRATION_SYSTEMS_SUCCESS',
  GET_INTEGRATION_SYSTEMS_FAILED = 'SHARED/GET_INTEGRATION_SYSTEMS_FAILED',

  GET_DICTIONARIES = 'SHARED/GET_DICTIONARIES',
  GET_DICTIONARIES_SUCCESS = 'SHARED/GET_DICTIONARIES_SUCCESS',
  GET_DICTIONARIES_FAILED = 'SHARED/GET_DICTIONARIES_FAILED',

  GET_CONFIGURATION_CHOICES = 'SHARED/GET_CONFIGURATION_CHOICES',
  GET_CONFIGURATION_CHOICES_SUCCESS = 'SHARED/GET_CONFIGURATION_CHOICES_SUCCESS',
  GET_CONFIGURATION_CHOICES_FAILED = 'SHARED/GET_CONFIGURATION_CHOICES_FAILED',
}

export const actionCreators = {
  setMainNavCollapsed: (payload: boolean) => createAction(ActionTypeKeys.SET_MAIN_NAV_COLLAPSED, payload),
  setSubNavCollapsed: (payload: boolean) => createAction(ActionTypeKeys.SET_SUB_NAV_COLLAPSED, payload),

  getIntegrationSystems: (payload: AccessToken) => createAction(ActionTypeKeys.GET_INTEGRATION_SYSTEMS, payload),
  getIntegrationSystemsSuccess: (payload: IntegrationSystem[]) => createAction(ActionTypeKeys.GET_INTEGRATION_SYSTEMS_SUCCESS, payload),
  getIntegrationSystemsFailed: () => createAction(ActionTypeKeys.GET_INTEGRATION_SYSTEMS_FAILED),

  getDictionaries: (payload: AccessToken) => createAction(ActionTypeKeys.GET_DICTIONARIES, payload),
  getDictionariesSuccess: (payload: any) => createAction(ActionTypeKeys.GET_DICTIONARIES_SUCCESS, payload),
  getDictionariesFailed: () => createAction(ActionTypeKeys.GET_DICTIONARIES_FAILED),

  getConfigurationChoices: (payload: AccessToken) => createAction(ActionTypeKeys.GET_CONFIGURATION_CHOICES, payload),
  getConfigurationChoicesSuccess: (payload: any) => createAction(ActionTypeKeys.GET_CONFIGURATION_CHOICES_SUCCESS, payload),
  getConfigurationChoicesFailed: () => createAction(ActionTypeKeys.GET_CONFIGURATION_CHOICES_FAILED),
};

export interface State {
  isMainNavCollapsed: boolean;
  isSubNavCollapsed: boolean;
  integrationSystems: IntegrationSystem[];
  dictionaries: any;
  configurationChoices: ConfigurationChoice[];
}

export const initialState: State = {
  isMainNavCollapsed: false,
  isSubNavCollapsed: false,
  integrationSystems: [] as IntegrationSystem[],
  dictionaries: {},
  configurationChoices: [] as ConfigurationChoice[],
};

export const sharedReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.SET_MAIN_NAV_COLLAPSED: {
      return {
        ...state,
        isMainNavCollapsed: action.payload,
      };
    }

    case ActionTypeKeys.SET_SUB_NAV_COLLAPSED: {
      return {
        ...state,
        isSubNavCollapsed: action.payload,
      };
    }

    case ActionTypeKeys.GET_INTEGRATION_SYSTEMS:
    case ActionTypeKeys.GET_DICTIONARIES:
    case ActionTypeKeys.GET_CONFIGURATION_CHOICES:
      return {
        ...state,
      };

    case ActionTypeKeys.GET_INTEGRATION_SYSTEMS_SUCCESS:
      return {
        ...state,
        integrationSystems: action.payload,
      };

    case ActionTypeKeys.GET_DICTIONARIES_SUCCESS:
      return {
        ...state,
        dictionaries: action.payload,
      };

    case ActionTypeKeys.GET_CONFIGURATION_CHOICES_SUCCESS:
      return {
        ...state,
        configurationChoices: action.payload,
      };

    case ActionTypeKeys.GET_INTEGRATION_SYSTEMS_FAILED:
    case ActionTypeKeys.GET_DICTIONARIES_FAILED:
    case ActionTypeKeys.GET_CONFIGURATION_CHOICES_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
